













import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import { isFreemium, isRecursive } from '@/utils/rules'
import { UserModule } from '@/store/modules/user'
import { getInvoiceData } from '@/api/shop'

@Component({
    name: 'PricesIndex',
    components: {
        VsContainer,
        VsTabs,
    },
})
export default class extends Vue {
    private customer = null

    get activeTab () {
        return this.$route.name
    }

    get user () {
        return UserModule.user
    }

    get isRecursive () {
        return isRecursive(this.user.configuration.rules)
    }

    get isFreemium () {
        return isFreemium(this.user.configuration.rules)
    }

    get hasShop () {
        return UserModule.user.configuration.rules.shop
    }

    get tabs () {
        const tabs = [
            {
                label: 'Abbonamento',
                id: 'subscriptions',
                to: {
                    name: 'subscriptions',
                },
            },
        ]
        if (!this.isRecursive || this.isFreemium) {
            tabs.push(
                {
                    label: 'Crediti Email',
                    id: 'credits-email',
                    to: {
                        name: 'credits-email',
                    },
                },
            )
        }
        tabs.push(
            {
                label: 'Crediti SMS',
                id: 'credits-sms',
                to: {
                    name: 'credits-sms',
                },
            },
            {
                label: 'Transazionali',
                id: 'credits-transactional',
                to: {
                    name: 'credits-transactional',
                },
            },
        )
        return tabs
    }

    beforeMount () {
        if (!this.hasShop) {
            this.$router.replace({
                name: 'myPlan',
            })
        }
        this.getCustomer()
    }

    private async getCustomer () {
        try {
            const resp = await getInvoiceData()
            this.customer = resp.data
        } catch (e) {
            console.log(e)
        }
    }
}
